<template>
  <div class="d-flex flex-column justify-content-center">
    <div class="wet-section">
      <div class="container">
        <h3 class="empty-ph-small">{{ strings.page404 && strings.page404.not_found }}</h3>
        <h2 class="empty-ph">{{ strings.page404 && strings.page404.header_text }}</h2>
        <p class="empty-ph">{{ strings.page404 && strings.page404.description_text }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import common from '@/mixins/common';
import { mapState } from 'vuex';
import wpSection from '@/enums/wpSection';

export default {
  mixins: [common],
  computed: {
    ...mapState(['strings']),
  },
  async created() {
    await Promise.all([this.getWordPressStrings([wpSection.PAGE404])]);
  },
};
</script>
